.react-aria-Tabs {
    --highlight-color: #bd0034;
    --text-color: rgb(180, 180, 180);
    --text-color-hover: #ffffff;
    --text-color-selected: #bd0034;
    --text-color-disabled: rgb(111, 111, 111);
    --border-color: "#bd0034";
    display: flex;

    &[data-orientation=horizontal] {
        flex-direction: column;
    }

    &[data-orientation=vertical] {
        flex-direction: row;
    }
}

.react-aria-TabList {
    display: flex;

    &[data-orientation=horizontal] {
        border-bottom: 1px solid gray;

        .react-aria-Tab {
            border-bottom: 3px solid var(--border-color, transparent);
        }
    }

    &[data-orientation=vertical] {
        flex-direction: column;
        border-right: 1px solid gray;

        .react-aria-Tab {
            border-right: 3px solid var(--border-color, transparent);
        }
    }
}

.react-aria-Tab {
    padding: 10px;
    cursor: default;
    outline: none;
    position: relative;
    color: var(--text-color);
    transition: color 200ms;

    &[data-hovered],
    &[data-focused] {
        color: var(--text-color-hover);
    }

    &[data-selected] {
        --border-color: var(--highlight-color);
        color: var(--text-color-selected);
    }

    &[data-disabled] {
        color: var(--text-color-disabled);

        &[data-selected] {
            --border-color: var(--text-color-disabled);
        }
    }

    &[data-focus-visible]:after {
        content: '';
        position: absolute;
        inset: 4px;
        border-radius: 4px;
        border: 2px solid var(--highlight-color);
    }
}

.react-aria-TabPanel {
    margin-top: 4px;
    padding: 10px;
    border-radius: 4px;
    outline: none;

    &[data-focus-visible] {
        box-shadow: inset 0 0 0 2px var(--highlight-color);
    }
}