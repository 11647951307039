.ventas-form__form-container {
    position: relative;
    color: rgb(140, 118, 118);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: linear-gradient(to top, #3f3f3f, #2d2d2d);
    border: 2px solid #3f3f3f;
    border-radius: 1rem;
    padding: 2rem;
    min-width: 350px;
}

.ventas-form__auto-data{
    color: rgb(140, 118, 118);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: linear-gradient(to top, #3b3b3b, #232323);
    border: 2px solid #3f3f3f;
    border-radius: 1rem;
    padding: 2rem;
    min-width: 350px;
}

.ventas-form__inputs-container {
    display: grid;
    /* grid-auto-flow: column; */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* grid-template-rows: repeat(7, auto); */
    overflow: visible;
    gap: 1rem;
}

.ventas-form__guardar-ingreso-btn {
    align-self: flex-start;
    width: 6.4rem;
    height: 3.2rem;
}

.ventas-form__submit-hint {
    color: lightgray;
    font-size: 0.6rem;
}
