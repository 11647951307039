


.form-container {
    color: rgb(140, 118, 118);
    display: flex;
    flex-direction: column;
    background: linear-gradient(to top, #3f3f3f, #2d2d2d);
    border: 2px solid #3f3f3f;
    border-radius: 1rem;
    padding: 2rem;
    min-width: 585px;
}

.propio-data-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;
}


.inputs-container {
    display: grid;
    /* grid-auto-flow: column; */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* grid-template-rows: repeat(7, auto); */
    gap: 1rem;
}


.modalidad-radio-container {
    /* display: flex; */
    /* flex-direction: column; */
    max-width: 200px;
    padding: 1.5rem;
    gap: 0.25rem;
    border-radius: 9px;
    border: 2px solid #2c2c2c;
    background: linear-gradient(to top, #2c2c2c, #222222);
}

.modalidad-radio-title {
    font-size: 13px;
}

.data-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 3rem;
}

.consignacion-data-container {
    margin-top: 4rem;
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    background-color: hsla(0, 0%, 0%, 0.2);
    border: 2px solid #3f3f3f;
    border-radius: 1rem;
    padding: 2rem;
    gap: 2rem;
    transition: height 300ms;
    
}



.datos-proveedor-container {
    /* grid-column: 1 / -1 */
    gap: 2rem;
    background-color: hsla(0, 0%, 0%, 0.1);
    padding: 0.5rem;
    border-radius: 8px;
    
    
}
.datos-proveedor {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem
}

.c-card-container {
    border-radius: 8px;
    overflow: hidden;
    flex-grow: 1;
}

.c-card {
    display: flex;
    flex-direction: column;
}

.c-card-header {
    padding: 0.2rem;
    color: white;
    background: linear-gradient(to top right, #790021, #202020);
}

.c-card-body {
    background-color: hsla(0, 0%, 0%, 0.2);
    padding: 0.5rem;
    gap: 3px;


}

.guardar-ingreso-btn {
    align-self: flex-start;
    width: 6.4rem;
    height: 3.2rem;
}

.submit-hint {
    color: lightgray;
    font-size: 0.6rem;
}