@keyframes bgColor {
    0%{
        background: #333;
    }
    20%{
        background: #333;
    }
    40%{
        background: #333;
    }
    60%{
        background: black;
    }
    80%{
        background: black;
    }
    100%{
        background: black;
    }
}
