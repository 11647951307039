.rc-tabs-tab {
    background-color: #f2f2f2;
}
.rc-tabs-nav-wrap {
    background-color: #444444;
}
.rc-tabs-tab-active, .rc-tabs-tab-active {
    color: white !important;
    background-color: #444444;
    border-bottom: 2px solid;
}
.rc-tabs-ink-bar {
    background-color: #444444;
}
.rc-tabs-top .rc-tabs-ink-bar {
    height: 0px;
    bottom: 0;
    left: 0;
}

.rc-tabs-top .rc-tabs-tab {
    margin: 0px;
    padding: 10px 50px 10px 50px;
    color: #000000;
}

.rc-tabs-tab:hover {
    color: green;
}
.rc-tabs-top {
    border-bottom: none;
}


.rc-tabs-bottom .rc-tabs-tab {
  padding-left: 30px;
  padding-right: 30px;
}