/* Pos punto de venta */
.container-pos {
  max-height: 100vh;
  min-height: 80vh;
  height: 85vh;
}

.container-pos-detail {
  max-height: 70vh;
  min-height: 50vh;
  height: 55vh;
  /* background-color: #443434; */
}

/* a {
  border-radius: 5px;
  display: flex;
} */

.overlayModal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  margin: auto;
  background: rgba(0, 0, 0, .5);
  padding: 20px;
  display: block;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow-y: auto;
}

.contenidoModal {
  top: 80px;
  bottom: 10px;
  min-height: 100px;
  position: relative;
  border-radius: 5px;
  padding: 10px;
}