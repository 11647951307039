input {
    outline-style: none;
    outline: none;
}

.iMELeM {
    background-color: hsla(0, 0%, 0%, 0) !important;
}

#search {
    grid-area: input;
    color: white;
    background-color: #1e1e1e;
    border-style: none;
    height: 30px;
}

#search:focus {
    border-style: none;
}


.search-input-container {
    position: relative;
    -webkit-appearance: none;
    padding-left: 3px;
    display: grid;
    grid-template-areas: "input button";
    grid-template-columns: 1fr auto;
    align-items: center;
    /* flex-wrap: nowrap; */
    color: white;
    background-color: #1e1e1e;
    border-style: none;
    border: 2px solid hsla(0, 0%, 0%, 0);
    border-radius: 4px;
    transition: border 300ms ease-in;
    width: fit-content;

    /* max-width: 330px; */
    #search {
        margin-right: 35px;
    }

    &>button {
        position: absolute;
        grid-area: button;
        right: 0;
        height: 30px;
        width: 30px;
        font-size: 2rem;
        font-size: 0.857rem;
        line-height: 0.857rem;

    }
}

.search-input-container:focus-within {
    border: 2px solid #bd0034;
    transition: border 100ms ease-in;
}