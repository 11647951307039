.fic-input-container {
  position: relative;
  margin-bottom: 8px;
}

.rs__input {
  color: white;
}

.rs__control {
  box-sizing: border-box;
  /* background: linear-gradient(to right, red, black) !important; */
  background: hsla(0, 0%, 0%, 0) !important;
  border: 0px !important;
  border-bottom: 2px solid #505050 !important;
  box-shadow: none !important;
  transition: all 400ms !important;
}

.rs__indicator {
  background: #1e1e1e;
}

.rs__indicators {
  background: #1e1e1e !important;
}

.rs__loading-indicator {
  background: #1e1e1e;
}


.rs__control:hover {
  border: 0px !important;
  border-bottom: 2px solid #686868 !important;
  box-shadow: none !important;
  transition: border 100ms;
}

.rs__control--is-focused {
  border: 0px !important;
  border-bottom: 2px solid #bababa !important;
  transition: all 200ms !important;
}

.rs__control--is-focused:hover {
  border: 0px !important;
  border-bottom: 2px solid #bababa !important;
  transition: all 200ms !important;
}

.rs__value-container {
  background-color: #1e1e1e !important;
  color: white;
}

.rs__dropdown-indicator {
  color: #bd0034 !important;
}


.rs__single-value {
  color: white !important;
}

.rs__value-container--has-value {
  color: white
}

.rs__menu {
  z-index: 500 !important;
  max-height: 160px !important;
  color: white !important;
  background-color: #1e1e1e !important;
  transition: background-color 300ms !important
}

.rs__menu-list {
  z-index: 500 !important;
  max-height: 160px !important;
}

.rs__option--is-focused {
  background-color: #575757 !important;
  transition: background-color 300ms !important;
}

.rs__option--is-selected {
  background-color: #737373 !important;
  transition: background-color 300ms !important
}

.rs__control.input-invalid {
  border: 2px solid #d40000cc;
  box-shadow: 0 2px 15px #d40000;
  transition: all 200ms;
}

.rs-select {
  background-color: red;
}


.rs-invalid__input {
  color: white;
}

.rs-invalid__control {
  box-sizing: border-box;
  /* background: linear-gradient(to right, red, black) !important; */
  background: hsla(0, 0%, 0%, 0) !important;
  border: 0px !important;
  border-bottom: 2px solid #d40000cc !important;
  box-shadow: 0 2px 15px #d40000 !important;
  transition: all 200ms;
}

.rs-invalid__indicator {
  background: #1e1e1e;
}

.rs-invalid__dropdown-indicator {
  color: #bd0034 !important;
}


.rs-invalid__control:hover {
  border: 0px !important;
  border-bottom: 2px solid #d40000cc !important;
  box-shadow: 0 2px 15px #d40000 !important;
  transition: all 200ms;
}

.rs-invalid__control--is-focused {
  border: 0px !important;
  border-bottom: 2px solid #d40000 !important;
  transition: all 200ms !important;
}

.rs-invalid__control--is-focused:hover {
  border: 0px !important;
  border-bottom: 2px solid #990202 !important;
  transition: all 200ms !important;
}

.rs-invalid__value-container {
  background-color: #1e1e1e !important;
  color: white;
}

.rs-invalid__single-value {
  color: white !important;
}

.rs-invalid__value-container--has-value {
  color: white
}

.rs-invalid__menu {
  z-index: 500;
  color: white !important;
  background-color: #1e1e1e !important;
  transition: background-color 300ms !important
}

.rs-invalid__option--is-focused {
  background-color: #575757 !important;
  transition: background-color 300ms !important
}

.rs-invalid__option--is-selected {
  background-color: #737373 !important;
  transition: background-color 300ms !important
}

.rs-invalid__control.input-invalid {
  border: 0px !important;
  border-bottom: 2px solid #d40000cc;
  box-shadow: 0 2px 15px #d40000;
  transition: all 200ms;
}

.rs-invalid-select {
  background-color: red;
}


.input-invalid-error-msj {
  font-size: 12px;
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  color: rgb(218, 27, 27);
  text-shadow: 0px 0px 3px black;
  text-align: left;
  padding: 0 8px;
}