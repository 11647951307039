.x-btn {
  text-shadow: -1px 0 #b2002f, 0 1px #b2002f, 1px 0 #b2002f, 0 -1px #b2002f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b2002f;
  background: hsl(344, 100%, 35%, 0.2);
  /* background-color: blue; */
  box-shadow: inset 0 0 0px #000000;
  transition:
    box-shadow 300ms,
    border 300ms;

  font-weight: 700;
  padding-bottom: 6px;
}

.x-btn:hover,
.x-btn:focus {
  border: 1px solid #ababab !important;
  transition: border 300ms;
}

.x-btn-pressed {
  text-shadow: -1px 0 #464646, -1px 0 #464646, -1px 0 #464646, -1px 0 #464646;
  border: 1px solid #464646;
  box-shadow: inset 0 0 10px #000000;
  transition:
    box-shadow 150ms,
    border 100ms,
    text-shadow 100ms;

}