input[type="file"] {
    display: none;
}
.custom-file-upload {
    color: #bd0034;
    border: 1px solid #bd0034;
    background-color: hsl(343, 100%, 37%, 0.1);
    border-radius: 5px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    transition: border 300ms ease-in;
    text-align: center;
    height: fit-content;
}

.custom-file-upload:hover {
    border: 1px solid #dfdfdf;
    transition: border 300ms ease-in;
}

.custom-file-upload:focus {
    border: 1px solid #ce9b9b;
    transition: border 300ms ease-in;
}

.input-invalid-error-msj{
    color: red;
}