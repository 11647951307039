.info-popover {
    --background-color: #171717;
    --border-color: #bd0034;

    border: 2px solid var(--border-color);
    box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
    border-radius: 6px;
    background: var(--background-color);
    outline: none;
    max-width: 265px;
    width: fit-content;
    padding: 2rem;
    box-sizing: border-box;
    color: whitesmoke;

    .react-aria-OverlayArrow svg {
        display: block;
        fill: var(--background-color);
        stroke: var(--border-color);
        stroke-width: 1px;
    }

    &[data-placement=top] {
        --origin: translateY(8px);

        &:has(.react-aria-OverlayArrow) {
            margin-bottom: 6px;
        }
    }

    &[data-placement=bottom] {
        --origin: translateY(-8px);

        &:has(.react-aria-OverlayArrow) {
            margin-top: 6px;
        }

        .react-aria-OverlayArrow svg {
            transform: rotate(180deg) translateY(3px);
        }
    }

    &[data-placement=right] {
        --origin: translateX(-8px);

        &:has(.react-aria-OverlayArrow) {
            margin-left: 6px;
        }

        .react-aria-OverlayArrow svg {
            transform: rotate(90deg);
        }
    }

    &[data-placement=left] {
        --origin: translateX(8px);

        &:has(.react-aria-OverlayArrow) {
            margin-right: 6px;
        }

        .react-aria-OverlayArrow svg {
            transform: rotate(-90deg);
        }
    }

    &[data-entering] {
        animation: popover-slide 200ms;
    }

    &[data-exiting] {
        animation: popover-slide 200ms reverse ease-in;
    }
}

@media (forced-colors: active) {
    .react-aria-Popover {
        --background-color: Canvas;
        --border-color: ButtonBorder;
    }
}

@keyframes popover-slide {
    from {
        transform: var(--origin);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}