
.modulo-card-container {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* color: hsla(206, 100%, 50%, 0.3); */
    height: 50vh;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;
    justify-items: center;
    gap: 5px;
}

/* .modulo-card {
    background-color: green;
    width: 7rem;
    height: 7rem;
} */

.modulo-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 200px;
    border-radius: 8px;
    background-color: #1e1e1e;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 
        transform 300ms ease-in-out,
        box-shadow 300ms ease-in-out;
  }
  
  .modulo-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 3px hsla(0, 0%, 65%, 0.3);
    transition: 
        transform 100ms ease-in-out,
        box-shadow 100ms ease-in-out;

  }
  
  .modulo-card-contenido {
    padding: 16px;
    text-align: center;
  }
  
  .modulo-card-titulo {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #bd0034;
  }
  
  .modulo-card-descripcion {
    margin: 8px 0 0;
    font-size: 14px;
    color: #878787;
  }