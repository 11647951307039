.fic-input-label {
    align-self: flex-start;
    margin: 0;
    color: rgb(236, 236, 236);
    /* margin-bottom: .5rem; */
}

.description {
    color: #9b9b9b;
}

/* Style for the input element */
.fic-input-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.fic-input-container.input-disabled {
    pointer-events: none;
}

.fic-input {
    height: 2.35rem;
    padding: 10px;
    border-style: none;
    border: 2px solid #505050;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    color: #e0e0e0;
    background-color: #1e1e1e;
    transition:
        box-shadow 400ms ease,
        border 400ms ease;
}



.fic-input-disabled {
    height: 2.35rem;
    padding: 10px;
    border-style: none;
    border: 2px solid #505050;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    color: rgb(111, 111, 111);
    background-color: #2e2e2e;
}

.fic-input:hover {
    outline: none;
    border: 2px solid #656565;
    /* box-shadow: 0 2px 10px hsla(0, 0%, 0%, 0.5); */
    transition: all 100ms;
}

.fic-input:focus {
    outline: none;
    border: 2px solid #bababa;
    /* box-shadow: 0 2px 10px hsla(0, 0%, 0%, 0.5); */
    transition: all 200ms;
}

.input-invalid {
    outline: none;
    border: 2px solid #d40000cc !important;
    box-shadow: 0 2px 15px #d40000 !important;
    transition: all 200ms;
}

.input-invalid-error-msj {
    color: rgb(218, 27, 27);
    text-shadow: 0px 0px 3px black;
    text-align: left;
    padding: 0 8px;
}