.cars-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

.car-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: whitesmoke;
    box-shadow: 5px 10px 15px black;
    border-radius: 0.7rem;
    overflow: hidden;
    transition: box-shadow 0.5s;
    max-width: 350px;
}

.car-card:hover {
    box-shadow: 5px 10px 15px -6px #54071c;
    transition: box-shadow 0.1;
}

.car-img, .placeholder-img {
    text-align: center;
    width: 105%;
    height: 250px;
    /* box-shadow: 0 4px 8px 2px gray; */
}

.car-name {
    color: #000000;
}

.car-info{
    display: flex;
}

.car-info-title{
    color: #1d1d1d;
}


.car-card-content{
    padding: 0px 15px;
    margin-top: auto;
}

.view-btn{
    color: white;
    margin: auto 0px 5px 0px;
    border-style: none;
    border-radius: 15px;
    background: linear-gradient(to left, #df0841, #8e062a );
    align-self: center;
    height: 2rem;
    width: 50%;
    transform: translate(0px, 0px);
    transition: transform 0.5s;
}

.view-btn:hover {
    transform: translate(0px, -2px);
    transition: transform 0.1s;
}

