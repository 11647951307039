.react-aria-RadioGroup {
    display: flex;
    flex-direction: column;
    gap: 4px;
  
    &[aria-orientation=horizontal] {
      flex-direction: row;
      align-items: center;
    }
  
    & [slot=description] {
      font-size: 12px;
    }
  
    & [slot=errorMessage] {
      font-size: 12px;
      color: var(--spectrum-global-color-red-600);
    }
  }
  
  .react-aria-Radio {
    --label-color: rgb(195, 195, 195);
    --deselected-color: rgb(136, 136, 136);
    --deselected-color-pressed: dimgray;
    --background-color: var(--spectrum-global-color-gray-50);
    --selected-color: rgb(202, 21, 57);
    --selected-color-pressed: lch(from slateblue calc(l - 10%) c h);
    --invalid-color: var(--spectrum-global-color-static-red-600);
    --invalid-color-pressed: var(--spectrum-global-color-static-red-700);
  
    display: flex;
    align-items: center;
    gap: 0.571rem;
    font-size: 0.95rem;
    color: var(--label-color);
  
    &:before {
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;
      box-sizing: border-box;
      border: 0.143rem solid var(--deselected-color);
      background: var(--background-color);
      border-radius: 1.286rem;
      transition: all 200ms;
    }
    
    &:hover {
      color: rgb(236, 236, 236);
      transition: color 200ms;
    }
  
    &[data-pressed]:before {
      border-color: var(--deselected-color-pressed);
    }
  
    &[data-selected] {
      &:before {
        border-color: var(--selected-color);
        border-width: 0.429rem;
      }
  
      &[data-pressed]:before {
        border-color: var(--selected-color-pressed);
      }
    }
  
    &[data-validation-state=invalid] {
      &:before {
        border-color: var(--invalid-color);
      }
  
      &[data-pressed]:before {
        border-color: var(--invalid-color-pressed);
      }
    }
  
    &[data-focus-visible]:before {
      box-shadow: 0 0 0 2px var(--spectrum-alias-background-color-default), 0 0 0 4px var(--selected-color);
    }
  
    &[data-disabled] {
      opacity: 0.4;
    }
  }
  
  /* @media (forced-colors: active) {
    .react-aria-Radio {
      forced-color-adjust: none;
  
      --label-color: ButtonText;
      --deselected-color: ButtonBorder;
      --deselected-color-pressed: ButtonBorder;
      --selected-color: Highlight;
      --selected-color-pressed: Highlight;
      --background-color: HighlightText;
      --invalid-color: LinkText;
      --invalid-color-pressed: LinkText;
      --spectrum-alias-background-color-default: Canvas;
  
      &[data-disabled] {
        opacity: 1;
        --deselected-color: GrayText;
        --selected-color: GrayText;
        --label-color: GrayText;
      }
    }
  } */