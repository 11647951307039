.login-overlay .panel-body{
    min-height: 244px;
}
.has-feedback{
    position: relative;
    width: 250px;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute
}   

.bienvenida{
    color:black;
    font-weight: bold;
    font-size: 25px;
}
.buttons-box{
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    
}


.blue-gradient-bg-Fage{
    height: 100vh;
    background: linear-gradient(to right, #e0e0e0, black);
}

.card2 {
    margin: 10px 10px
}


.image {
    width: 110px;
    height: 100px
}


.border-line {
    border-right: 1px solid #EEEEEE
}


.line {
    height: 1px;
    width: 45%;
    background-color: #E0E0E0;
    margin-top: 15px
}


a {
    color: inherit;
    cursor: pointer
}


.bg-foo {
    color: black;
    background: linear-gradient(to right, #444444, #cdcdcd);

}

small{
    color: white;
}

@media screen and (max-width: 991px) {
    .image {
        width: 110px;
        height: 100px
    }

    .border-line {
        border-right: none
    }

    .card2 {
        border-top: 1px solid #EEEEEE !important;
        margin: 0px 15px
    }
}
