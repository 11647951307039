/* .fic-dialog {
    outline: none;
    padding: 30px;

    .react-aria-Heading {
        line-height: 1em;
        margin-top: 0;
    }

    .react-aria-Button {
        margin-top: 20px;
        margin-right: 8px;
    }

    [slot="title"] {
        color: #bd0034;
    }
} */

.react-aria-ModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--visual-viewport-height);
    background: rgba(0 0 0 / .5);
    display: flex;
    align-items: center;
    justify-content: center;

    &[data-entering] {
        animation: modal-fade 200ms;
    }

    &[data-exiting] {
        animation: modal-fade 150ms reverse ease-in;
    }
}

/* .react-aria-Modal { */

.fic-dialog {
    outline: none;
    width: fit-content;
    box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
    border-radius: 6px;
    /* background: var(--page-background); */
    background: #171717;
    border: 1px solid #bd0034;
    outline: none;
    /* max-width: 300px; */

    h2 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    & [slot=title] {
        line-height: 1em;
        margin-top: 0;
        color: #bd0034;
    }

    &[data-entering] {
        animation: modal-zoom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

}

@keyframes modal-fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes modal-zoom {
    from {
        transform: scale(0.8);
    }

    to {
        transform: scale(1);
    }
}