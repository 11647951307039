.react-aria-TextField {
    /* --focus-ring-color: slateblue; */
    --focus-ring-color: #bababa;

    position: relative;

    display: flex;
    flex-direction: column;
    width: fit-content;

    .react-aria-TextArea {
        outline: none;
        height: 2.85rem;
        padding: 10px;
        border-style: none;
        border: 2px solid #505050;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        font-size: 16px;
        color: #e0e0e0;
        background-color: #1e1e1e;
        transition:
            box-shadow 400ms ease,
            border 250ms ease;


        &[data-invalid] {
            border-color: #d40000;
            /* border: 2px solid #d40000 !important; */
            outline: none;
            box-shadow: 0 2px 15px #d40000 !important;
            transition: all 200ms;
        }

        &[data-hovered] {
            border-color: #656565;
            transition: all 100ms;
        }


        &[data-focused] {
            border-color: var(--focus-ring-color);
            box-shadow: 0 0 0 1px var(--focus-ring-color);
        }

        &[data-disabled] {
            border-color: #505050;
            color: rgb(111, 111, 111);
            /* border: 2px solid #505050; */
            /* 
            height: 2.35rem;
            padding: 10px;
            border-style: none;
            border-radius: 5px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            font-size: 16px;
            background-color: #2e2e2e; */
        }

    }

    [slot=description] {
        font-size: 12px;
        color: #9b9b9b;
        margin-left: 0.325rem;
    }

    [slot=errorMessage] {
        font-size: 12px;
        position: absolute;
        top: 92%;
        left: 0;
        display: block;
        color: rgb(218, 27, 27);
        text-shadow: 0px 0px 3px black;
        text-align: left;
        padding: 0 8px;
    }
}