@keyframes slide-up {
  0% {
    transform: translateY(30%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  100% {
    opacity: 0;
  }

  0% {
    opacity: 1;
  }
}

.form-M {
  min-height: 205px;
}

.DFModal {
  padding: 0px;
  border-radius: 15px;
  min-height: 205px;
  min-width: 200px;
  /* width: 60vh; */
  box-shadow: 15px 10px 15px hsla(0, 0%, 0%, 0.48);
  /* background: linear-gradient(to top, #3f3f3f, #2d2d2d); */
  background: #171717;
  border: 1px solid #4c4c4c;
}

.DFModal::backdrop {
  background-color: hsla(360, 100%, 0%, 0.5);
}

.DFModal[open] {
  animation:
    slide-up 200ms forwards,
    fade-in 200ms forwards;
}

.DFModal[open]::backdrop {
  animation: fade-in 300ms forwards;

}

.DFModal[closing] {
  animation:
    fade-out 100ms forwards;
}

.DFModal[closing]::backdrop {
  animation: fade-out 100ms forwards;
}

.modal-H {
  display: flex;
  overflow: hidden;
  max-height: 3rem;
  justify-content: space-between;
  background-color: hsla(0, 0%, 0%, 0.5);
  position: sticky;
  top: 0;
  z-index: 99;
  border-radius: 15px 15px 0 0;
  /* padding: 1rem; */
}


.title-H {
  margin-left: 3%;
  margin-top: auto;
  margin-bottom: auto;
  color: #b2002f;
  font-size: 1.2rem;
}

.close-btn {
  text-shadow: -1px 0 #b2002f, 0 1px #b2002f, 1px 0 #b2002f, 0 -1px #b2002f;
  border-radius: 4px 15px 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  border: 1px solid #b2002f;
  /* background: linear-gradient(to top, #7e0022, #323232); */
  background: hsl(344, 100%, 35%, 0.35);
  /* background-color: blue; */
  box-shadow: inset 0 0 0px #000000;
  transition:
    box-shadow 300ms,
    border 300ms;

  font-weight: 700;
  font-size: 2rem;
}

.close-btn:hover,
.close-btn:focus {
  border: 1px solid #ababab !important;
  transition: border 300ms;
}


.close-btn-pressed {
  text-shadow: -1px 0 #464646, -1px 0 #464646, -1px 0 #464646, -1px 0 #464646;
  border: 1px solid #464646;
  box-shadow: inset 0 0 10px #000000;
  transition:
    box-shadow 150ms,
    border 300ms,
    text-shadow 300ms;
}