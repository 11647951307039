.react-aria-NumberField {
    margin-bottom: 8px;
    width: 100%;
    position: relative;

    &:focus-within {

        .react-aria-Input {
            outline: none;
            border-color: #656565;
            /* box-shadow: 0 2px 10px hsla(0, 0%, 0%, 0.5); */
            transition: all 100ms;
        }
    }

    .react-aria-Group {
        width: 100%;
        display: flex;
        display: grid;
        grid-template-areas: "input input input plus"
            "input input input minus"
        ;
    }


    .react-aria-Button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px;
        /* min-height: 2.35rem; */
        /* height: 100%; */
        /* max-height: 1.1525rem; */
        height: 1.175rem;
        border: 1px solid #009c03;
        background-color: hsla(121, 100%, 31%, 0.2);
        color: #009c03;
        box-shadow: inset 0 0 0px #000000;
        outline: none;
        transition:
            box-shadow 250ms,
            border 250ms;
        font-weight: 700;
        font-size: large;
        appearance: none;
        font-size: 1.2rem;
        width: 2.3rem;
        margin: 0;
        padding: 0;
        outline: none;


        &[data-disabled] {
            border-color: #505050;
            color: rgb(111, 111, 111);
            background-color: #1e1e1e;
        }

        &[data-pressed] {
            /* text-shadow: -1px 0 #464646, -1px 0 #464646, -1px 0 #464646, -1px 0 #464646 !important; */
            border-color: #464646;
            box-shadow: inset 0 0 10px #000000;
            transition:
                box-shadow 50ms,
                border 15ms,
                text-shadow 15ms;
        }

        &[data-hovered] {
            border-color: #656565;
            transition: all 100ms;
        }

        &[data-focused] {
            border-color: var(--focus-ring-color);
            box-shadow: 0 0 0 1px var(--focus-ring-color);
        }
    }

    .btn-plus {
        grid-area: plus;
        border-radius: 0 5px 0 0;
        /* border-bottom: none; */
    }

    .btn-minus {
        grid-area: minus;
        border-radius: 0 0 5px 0;
        /* border-top: none; */
    }

    .react-aria-Input {
        grid-area: input;
        height: 2.35rem;
        width: 100%;
        padding: 10px;
        border-style: none;
        border: 2px solid #505050;
        border-radius: 5px 0px 0px 5px;
        /* border-radius: 5px; */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        font-size: 16px;
        color: #e0e0e0;
        background-color: #1e1e1e;
        transition:
            box-shadow 400ms ease,
            border 400ms ease;




        &[data-invalid] {
            outline: none;
            border-color: #d40000;
            box-shadow: 0 2px 15px #d40000;
            transition: all 200ms;
        }

        &[data-hovered] {
            border-color: #656565;
            transition: all 100ms;
        }

        &[data-focused] {
            border-color: var(--focus-ring-color);
            box-shadow: 0 0 0 1px var(--focus-ring-color);
        }

        &[data-disabled] {
            border-color: #505050;
            color: rgb(111, 111, 111);
        }
    }

    [slot=description] {
        color: #9b9b9b;
    }

    [slot=errorMessage] {
        font-size: 12px;
        position: absolute;
        top: 100%;
        left: 0;
        display: block;
        color: rgb(218, 27, 27);
        text-shadow: 0px 0px 3px black;
        text-align: left;
        padding: 0 8px;
    }
}