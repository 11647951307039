.file-drop {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: none;
    padding: 10px;
    border-style: none;
    border: 2px dashed #505050;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    color: #e0e0e0;
    background-color: #1e1e1e;
    transition: 
        box-shadow 400ms ease,
        border 600ms ease;
    cursor: pointer;
}
.file-drop:hover {
    border: 2px dashed rgb(235, 235, 235);
    transition: 
        border 100ms ease;
}

.error-text-without-img {
    color: rgb(218, 27, 27);
    text-shadow: 2px 2px 3px black;
    text-align: left;
    padding: 0 8px;
}

.error-text-with-img {
    color: rgb(218, 27, 27);
    text-shadow: 1px 1px 4px black, 1px 1px 4px black, 1px 1px 4px black;
    text-align: left;
    padding: 0 8px;
    position: absolute;
    transform: translateY(30px);
}

.file-drop-input-invalid {
    border: 2px dashed #d40000;
    transition: all 200ms;
}


.file-drop-input-disabled {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: none;
    padding: 10px;
    border-style: none;
    border: 2px dashed #505050;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    color: #e0e0e0;
    background-color: #1e1e1e;
    transition: 
        box-shadow 400ms ease,
        border 600ms ease;
}