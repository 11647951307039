.react-aria-DateRangePicker {
    --field-border: #505050;
    --field-background: #1e1e1e;
    --text-color: #e0e0e0;
    --text-color-placeholder: #afafaf;
    --text-color-invalid: rgb(218, 27, 27);
    --highlight-background: #bababa;
    /* --highlight-background: #bd0034; */
    --highlight-foreground: white;
    --highlight-background-invalid: rgb(218, 27, 27);

    color: var(--text-color);

    .react-aria-Group {
        display: flex;
        align-items: center;
        width: fit-content;
        /* min-width: 220px; */
        /* max-width: 100%; */
        height: 2.35rem;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        /* margin-left: 0.5rem; */
        margin-bottom: 5px;
        padding: 2px 2px 2px 8px;
        border: 2px solid var(--field-border);
        border-radius: 6px;
        background: var(--field-background);
        white-space: nowrap;
        transition: border-color 200ms ease-out;
        outline: none;

        &[data-hovered] {
            border-color: #656565;
        }


        &[data-focus-within] {
            transition: all 100ms;
            border-color: var(--highlight-background);
            box-shadow: 0 0 0 2px var(--highlight-background);
        }

        &>span {
            color: #bd0034;
            padding: 0 4px;
        }

        [slot=end] {
            margin-right: 2rem;
            flex: 1;
        }
    }

    /* [slot=start]+span { */

    .date-range-picker-btn {
        background: hsl(343, 100%, 37%, 0.2);
        color: #bd0034;
        border-radius: 4px;
        appearance: none;
        vertical-align: middle;
        outline: none;
        border: 1px solid #bd0034;
        margin-left: auto;
        width: 1.429rem;
        height: 1.429rem;
        padding: 0;
        font-size: 0.857rem;
        box-sizing: content-box;
        flex-shrink: 0;
        position: sticky;
        right: 0;

        &[data-focus-visible] {
            box-shadow: 0 0 0 2px #bd0034;
        }

    }

    .react-aria-FieldError {
        font-size: 12px;
        color: var(--text-color-invalid);
    }

    .react-aria-DateInput {
        display: flex;
        align-items: center;
        width: unset;
        min-width: unset;
        padding: unset;
        border: unset;
        box-shadow: unset;
    }
}

.react-aria-RangeCalendar {
    --focus-background: #bd0034;
    --focus-foreground: white;
    --button-background: hsl(343, 100%, 37%, 0.2);
    --cell-selected-background: hsla(343, 100%, 37%, 0.5);
    --button-background-pressed: hsl(343, 100%, 37%, 0.5);
    --button-border: #bd0034;
    --text-color: #e0e0e0;
    --text-color-disabled: #b3b3b3;
    --text-color-invalid: rgb(218, 27, 27);
    --pressed-color: hsla(214, 100%, 50%, 0.62);
    --unavailable-color: rgba(255, 115, 0, 0.62);
    --invalid-color: rgb(218, 27, 27);

    width: fit-content;
    color: var(--text-color);

    & header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 4px .5rem 4px;

        .react-aria-Heading {
            flex: 1;
            margin: 0;
            text-align: left;
            font-size: 1.375rem;
        }

        .react-aria-Button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--button-background);
            border: 1px solid var(--button-border);
            color: #bd0034;
            box-shadow: 0 1px 2px rgba(0 0 0 / 0.1);
            text-decoration: none;
            border-radius: 4px;
            appearance: none;
            vertical-align: middle;
            font-size: 3rem;
            width: 2rem;
            height: 2rem;
            padding: 0px;
            padding-bottom: 0.825rem;
            text-align: center;
            margin: 0px;
            outline: none;
            transition: background-color 100ms ease-in;

            &[data-focus-visible] {
                border-color: var(--focus-background);
                box-shadow: 0 0 0 1px var(--focus-background);
            }

            &[data-pressed] {
                background: var(--button-background-pressed);
            }

            &[slot=previous] {
                margin-right: 0.25rem;
            }

            &[slot=next] {
                margin-left: 0.25rem;
            }
        }
    }

    .react-aria-CalendarHeaderCell {
        text-align: center;
    }

    .react-aria-CalendarCell {
        width: 2rem;
        line-height: 2rem;
        text-align: center;
        border-radius: 6px;
        cursor: default;
        outline: none;
        border: 2px solid hsl(0, 0%, 7%, 0.5);
        margin: -1px;
        transition: background-color 70ms ease-in;

        &[data-outside-month] {
            display: none;
        }

        &[data-pressed] {
            background: var(--pressed-color);
        }

        &[data-focus-visible] {
            box-shadow: 0 0 0 2px var(--focus-background);
        }

        &[data-selected] {
            background: var(--cell-selected-background);
            color: var(--focus-foreground);
        }

        &[data-selection-start] {
            background: var(--focus-background);
            color: var(--highlight-foreground);
        }

        &[data-selection-end] {
            background: var(--focus-background);
            color: var(--highlight-foreground);
        }

        &[data-unavailable] {
            text-decoration: line-through;
            color: var(--unavailable-color);
            background: rgba(255, 115, 0, 0.10);
        }

        &[data-invalid] {
            background: var(--invalid-color);
            color: var(--focus-foreground);
        }

        &[data-disabled] {
            border-color: #505050;
            color: rgb(111, 111, 111);
        }
    }


}


@layer popover {
    .react-aria-Popover {
        max-width: unset;
        padding: 1.25rem;
    }
}