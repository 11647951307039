.react-aria-Checkbox {
  --label-color: rgb(236, 236, 236);
  --deselected-color: gray;
  --deselected-color-pressed: #981c35;
  --selected-color: rgb(202, 21, 57);
  --selected-color-pressed: lch(from rgb(202, 21, 57) calc(l - 10%) c h);
  --checkmark-color: white;
  --invalid-color: red;
  --invalid-color-pressed: red;
  --focus-ring-color: #bababa;

  display: flex;
  align-items: center;
  gap: 0.571rem;
  font-size: 0.95rem;
  color: var(--label-color);

  .checkbox {
    width: 1.443rem;
    height: 1.443rem;
    border: 2px solid var(--deselected-color);
    border-radius: 4px;
    transition: all 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
    
    
  }
  
  & svg {
    width: 1rem;
    height: 1rem;
    fill: none;
    stroke: var(--checkmark-color);
    stroke-width: 3px;
    stroke-dasharray: 22px;
    stroke-dashoffset: 66;
    transition: all 200ms;
  }
  
  &[data-pressed] .checkbox {
    border-color: var(--deselected-color-pressed);
  }
  
  &[data-selected],
  &[data-indeterminate] {
    .checkbox {
      border-color: var(--selected-color);
      background: var(--selected-color);
    }
    
    &[data-pressed] .checkbox {
      border-color: var(--selected-color-pressed);
      background: var(--selected-color-pressed);
    }
    
    & svg {
      stroke-dashoffset: 44;
    }
  }
  
  &[data-validation-state=invalid] {
    .checkbox {
      border-color: var(--invalid-color);
    }
    
    &[data-pressed] .checkbox {
      border-color: var(--invalid-color-pressed);
    }
    
    &[data-selected],
    &[data-indeterminate] {
      .checkbox {
        background: var(--invalid-color);
      }
      
      &[data-pressed] .checkbox {
        background: var(--invalid-color-pressed);
      }
    }
  }

  &[data-focused] {
    .checkbox {

    outline: 2px solid var(--focus-ring-color);
    outline-offset: -1px;
    }
  }
  
  &[data-indeterminate] {
    & svg {
      stroke: none;
      fill: var(--checkmark-color);
    }
  }
  
  &[data-focus-visible] .checkbox {
    box-shadow: 0 0 0 2px var(--spectrum-alias-background-color-default), 0 0 0 4px var(--selected-color);
  }

  &[data-disabled] {
    opacity: 0.4;
  }
}

@media (forced-colors: active) {
  .react-aria-Checkbox {
    forced-color-adjust: none;

    --label-color: ButtonText;
    --deselected-color: ButtonBorder;
    --deselected-color-pressed: ButtonBorder;
    --selected-color: Highlight;
    --selected-color-pressed: Highlight;
    --checkmark-color: HighlightText;
    --invalid-color: LinkText;
    --invalid-color-pressed: LinkText;
    --spectrum-alias-background-color-default: Canvas;

    &[data-disabled] {
      opacity: 1;
      --deselected-color: GrayText;
      --selected-color: GrayText;
      --label-color: GrayText;
    }
  }
}