.react-aria-DatePicker {
  position: relative;
  --text-color-invalid: rgb(218, 27, 27);
  --text-color: #e0e0e0;
  color: var(--text-color);

  .react-aria-Group {
    display: flex;
    width: auto;
    align-items: center;
  }

  .react-aria-Button {
    background: hsl(343, 100%, 37%, 0.2);
    color: #bd0034;
    border-radius: 4px;
    appearance: none;
    margin-left: -1.929rem;
    width: 1.429rem;
    height: 1.429rem;
    padding: 0;
    vertical-align: middle;
    font-size: 0.857rem;
    outline: none;
    box-sizing: content-box;
    /* border: 2px solid #bd0034; */
    border: 1px solid #bd0034;

    &[data-focus-visible] {
      box-shadow: 0 0 0 2px #bd0034;
    }
  }

  [slot=description] {
    font-size: 12px;
  }

  [slot=errorMessage] {
    font-size: 12px;
    position: absolute;
    top: 92%;
    left: 0;
    display: block;
    color: rgb(218, 27, 27);
    text-shadow: 0px 0px 3px black;
    text-align: left;
    padding: 0 8px;
  }

  &[data-invalid] {
    .react-aria-DateInput:after {
      content: '🚫' / '';
      content: '🚫';
      alt: ' ';
      flex: 1;
      text-align: end;
    }
  }

  &[data-disabled] {
    .react-aria-DateInput {
      border-color: #505050 !important;
      color: rgb(111, 111, 111) !important;

      .react-aria-DateSegment {
        border-color: #505050 !important;
        color: rgb(111, 111, 111) !important;

      }
    }

    .react-aria-Button {
      color: rgb(111, 111, 111);
      border-color: rgb(111, 111, 111);
      background-color: rgba(111, 111, 111, 0.2);
    }
  }

}

.react-aria-DateInput {
  --field-border: #505050;
  --field-background: #1e1e1e;
  --text-color: #e0e0e0;
  --text-color-placeholder: #afafaf;
  --text-color-invalid: rgb(218, 27, 27);
  --focus-background: #bd0034;
  --focus-foreground: white;
  --focus-background-invalid: rgb(218, 27, 27);
  --focus-ring-color: #bababa;

  display: flex;
  padding: 4px 2.5rem 4px 8px;
  border: 2px solid var(--field-border);
  border-radius: 6px;
  background: var(--field-background);
  width: 100%;
  min-width: 250px;
  white-space: nowrap;
  transition: border 250ms ease;
  min-height: 2.35rem;

  .react-aria-label {
    margin: 0;
    color: rgb(236, 236, 236);
    margin-bottom: .5rem;
  }


  &:has([data-invalid]) {
    border-color: #d40000;
    /* border: 2px solid #d40000 !important; */
    outline: none;
    box-shadow: 0 2px 15px #d40000 !important;
    transition: all 200ms;
  }

  &[data-hovered] {
    transition: all 100ms;
    border-color: #656565;
  }


  &[data-focused] {
    border-color: var(--focus-ring-color);
    box-shadow: 0 0 0 1px var(--focus-ring-color);
  }

  &[data-focus-within] {
    border-color: var(--focus-ring-color);
    box-shadow: 0 0 0 1px var(--focus-ring-color);
  }

  &[data-disabled] {
    border-color: #505050;
    color: rgb(111, 111, 111);
  }
}

.react-aria-DateSegment {
  padding: 0 2px;
  font-variant-numeric: tabular-nums;
  text-align: end;
  color: var(--text-color);

  &[data-type=literal] {
    padding: 0;
  }

  &[data-placeholder] {
    color: var(--text-color-placeholder);
    font-style: italic;
  }

  &:focus {
    color: var(--focus-foreground);
    background: var(--focus-background);
    outline: none;
    border-radius: 4px;
    caret-color: transparent;
  }

  &[data-invalid] {
    color: var(--text-color-invalid);

    &:focus {
      background: var(--focus-background-invalid);
      color: var(--focus-foreground);
    }
  }
}

.react-aria-Calendar {
  --focus-background: #bd0034;
  --focus-foreground: white;
  --button-background: hsl(343, 100%, 37%, 0.2);
  --button-background-pressed: hsl(343, 100%, 37%, 0.5);
  --button-border: #bd0034;
  --text-color: #e0e0e0;
  --text-color-disabled: #b3b3b3;
  --text-color-invalid: rgb(218, 27, 27);
  --pressed-color: hsla(214, 100%, 50%, 0.62);
  --unavailable-color: rgba(255, 115, 0, 0.62);
  --invalid-color: rgb(218, 27, 27);

  width: fit-content;
  color: var(--text-color);

  & header {
    display: flex;
    align-items: center;
    margin: 0 4px .5rem 4px;

    .react-aria-Heading {
      flex: 1;
      margin: 0;
      text-align: center;
      font-size: 1.375rem;
    }

    .react-aria-Button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--button-background);
      border: 1px solid var(--button-border);
      color: #bd0034;
      box-shadow: 0 1px 2px rgba(0 0 0 / 0.1);
      text-decoration: none;
      border-radius: 4px;
      appearance: none;
      vertical-align: middle;
      font-size: 3rem;
      width: 2rem;
      height: 2rem;
      padding: 0px;
      padding-bottom: 0.825rem;
      text-align: center;
      margin: 0px;
      outline: none;
      transition: background-color 100ms ease-in;

      &[data-focus-visible] {
        border-color: var(--focus-background);
        box-shadow: 0 0 0 1px var(--focus-background);
      }

      &[data-pressed] {
        background: var(--button-background-pressed);
      }

      &[data-disabled] {
        color: rgb(111, 111, 111);
        border-color: rgb(111, 111, 111);
        background-color: rgba(111, 111, 111, 0.2);
      }
    }
  }

  .react-aria-CalendarCell {
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    border-radius: 6px;
    cursor: default;
    outline: none;
    border: 2px solid hsl(0, 0%, 7%, 0.5);
    margin: -1px;
    transition: background-color 70ms ease-in;

    &[data-outside-month] {
      display: none;
    }

    &[data-pressed] {
      background: var(--pressed-color);
    }

    &[data-focus-visible] {
      box-shadow: 0 0 0 2px var(--focus-background);
    }

    &[data-selected] {
      background: var(--focus-background);
      color: var(--focus-foreground);
    }

    &[data-unavailable] {
      text-decoration: line-through;
      color: var(--unavailable-color);
      background: rgba(255, 115, 0, 0.10);
    }

    &[data-invalid] {
      background: var(--invalid-color);
      color: var(--focus-foreground);
    }

    &[data-disabled] {
      border-color: #505050;
      color: rgb(111, 111, 111);
    }
  }

  [slot=errorMessage] {
    font-size: 12px;
    color: var(--text-color-invalid);
  }
}

.react-aria-Popover {
  overflow: auto;
  border: 1px solid hsl(343, 100%, 37%, 0.7);
  box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
  border-radius: 6px;
  background: #1e1e1e;
  padding: 1.25rem;
  z-index: 999;

  &[data-placement=top] {
    --origin: translateY(8px);
  }

  &[data-placement=bottom] {
    --origin: translateY(-8px);
  }

  &[data-entering] {
    animation: slide 200ms;
  }

  &[data-exiting] {
    animation: slide 200ms reverse ease-in;
  }
}

@keyframes slide {
  from {
    transform: var(--origin);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}